import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { NotFoundComponent } from '@wilson/wilsonng';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UttAuthGuard } from './guards/utt-auth.guard';
import { FhpAuthGuard } from './guards/fhp-auth.guard';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { BreadcrumbRootType } from './helpers/breadcrumbRoots';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    // All users with FHP access will also have UTT access. Not the other way around though
    // So all users with FHP access will be able to access all "UTT guarded" routes as well
    canActivate: [UttAuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [FhpAuthGuard],
        title: 'Dashboard',
      },
      {
        path: 'admin',
        component: AdminDashboardComponent,
        canActivate: [FhpAuthGuard],
        title: 'FHP Admin Dashboard',
      },
      {
        path: 'reports',
        title: 'School & District Reports',
        canActivateChild: [UttAuthGuard],
        data: { canLoad: false, rootType: BreadcrumbRootType.UTT },
        loadChildren: () =>
          import('./pages/reports/reports-routing.module').then(
            (m) => m.ReportsRoutingModule
          ),
      },
      {
        path: 'utt',
        title: 'Unit Test Tracker',
        canActivateChild: [UttAuthGuard],
        data: { canLoad: false, rootType: BreadcrumbRootType.UTT },
        loadChildren: () =>
          import('./pages/roster/roster-routing.module').then(
            (m) => m.RosterRoutingModule
          ),
      },
      {
        path: 'fhp',
        title: 'FUN HUB Practice',
        canActivateChild: [FhpAuthGuard],
        data: { canLoad: false, rootType: BreadcrumbRootType.FHP },
        loadChildren: () =>
          import('./pages/fun-hub-practice/fun-routing.module').then(
            (m) => m.FunRoutingModule
          ),
      },
      {
        path: 'management',
        title: 'Setup & Management',
        canActivateChild: [UttAuthGuard],
        data: { canLoad: false },
        loadChildren: () =>
          import('./pages/management/management-routing.module').then(
            (m) => m.ManagementRoutingModule
          ),
      },
    ],
  },
  {
    // p = 'public'
    path: 'p',
    loadChildren: () =>
      import('./public/public.module').then((m) => m.PublicModule),
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
