import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecordingSettingsService } from '../../services/recording-settings.service';
import { finalize, iif } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-admin-student-recording-dialog',
  styleUrls: ['./admin-student-recording-dialog.component.scss'],
  templateUrl: './admin-student-recording-dialog.component.html',
})
export class AdminStudentRecordingDialogComponent {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  responded: boolean = false;

  constructor(
    private recordingSettingsService: RecordingSettingsService,
    private userService: UserService
  ) {}

  dismiss() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

  respondToTerms(accepted: boolean) {
    iif(
      () => this.userService.isDistrictAdmin(),
      this.recordingSettingsService.setDistrictRecordingPermission(accepted),
      this.recordingSettingsService.setSchoolsRecordingPermission(accepted)
    )
      .pipe(finalize(() => (this.responded = true)))
      .subscribe();
  }
}
