<p-dialog
  [(visible)]="visible"
  [closable]="false"
  [modal]="true"
  [draggable]="false"
  [closeOnEscape]="false"
  [style]="{ width: '50rem' }"
  (visibleChange)="visibleChange.emit($event)"
>
  <ng-template pTemplate="header">
    <div class="flex align-items-center justify-content-center w-100 p-3 pb-0">
      <h4 class="fw-bold mb-0">Read Aloud Feature</h4>
    </div>
  </ng-template>

  <div class="px-3">
    <p>
      Your School Administrator has turned on the
      <span class="fw-bold">Read Aloud Feature</span>
      in FUN HUB® Practice, which allows for voice recording of your students’
      pronunciation of letters, sounds, sentences, and words, to support oral
      reading feedback.
    </p>
    <p>
      When creating an assignment, a microphone icon will be visible to see
      which activities have the Read Aloud Feature.
    </p>
    <p>
      You will also have access to the student’s recordings and have the option
      to score how well they did through the individual student report.
    </p>
    <p>
      Please note that you have the ability to adjust this setting at the
      individual student level for their classroom on the FHP Classes page.
    </p>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-center gap-3 pb-3">
      <p-button
        [routerLink]="['/fhp/class']"
        label="Manage Settings in FHP Classes"
        severity="primary"
        [outlined]="true"
      />
      <p-button
        (onClick)="dismiss()"
        label="Ok"
        severity="primary"
        styleClass="px-5"
      />
    </div>
  </ng-template>
</p-dialog>
