<div
  *ngIf="tenants.length > 1"
  class="d-flex align-items-center justify-content-center gap-2"
>
  <small>Roster: </small>

  <i
    class="wi-icon wi-help d-inline-block"
    [pTooltip]="
      'Your school is currently using Clever to roster your students, but you have previously created students in FUN HUB. You are always defaulted to students from Clever - but you can refer back to your manually created student data by using this dropdown.'
    "
    tooltipPosition="bottom"
  ></i>

  <p-dropdown
    appendTo="body"
    styleClass="w-100"
    [options]="tenants"
    [(ngModel)]="selectedTenant"
    (onChange)="onSelectedTenantChange()"
  >
    <ng-template pTemplate="selectedItem">
      <div class="flex align-items-center gap-3">
        <img
          src="/assets/img/tenants/type-{{ selectedTenant.type }}.svg"
          alt="Icon for tenant"
          style="height: 1.25em"
        />
        <div>{{ selectedTenant.name }}</div>
      </div>
    </ng-template>
    <ng-template let-tenant pTemplate="item">
      <div class="flex align-items-center gap-2">
        <img
          src="/assets/img/tenants/type-{{ tenant.type }}.svg"
          alt="Icon for tenant"
          style="height: 1.25em"
        />
        <div>{{ tenant.name }}</div>
      </div>
    </ng-template>
  </p-dropdown>
</div>
