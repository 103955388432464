<p-dialog
  [(visible)]="visible"
  [closable]="false"
  [modal]="true"
  [draggable]="false"
  [closeOnEscape]="false"
  [styleClass]="responded ? 'next-steps-dialog' : 'terms-dialog'"
  (visibleChange)="visibleChange.emit($event)"
>
  <ng-template pTemplate="header">
    <ng-container *ngIf="responded; else header">
      <div
        class="flex align-items-center justify-content-center w-100 p-3 pb-0"
      >
        <h5 class="fw-bold text-center m-0">Thank you</h5>
      </div>
    </ng-container>

    <ng-template #header>
      <div
        class="flex align-items-center justify-content-center w-100 p-3 pb-0"
      >
        <h4 class="fw-bold mb-0">Read Aloud Feature</h4>
      </div>
    </ng-template>
  </ng-template>

  <div class="px-3">
    <ng-container *ngIf="responded; else prompt">
      <p class="text-center">
        To make changes to the recording settings, go to your settings page.
      </p>
    </ng-container>
  </div>

  <ng-template #prompt>
    <p class="fw-normal">
      The <span class="fw-bold">Read Aloud Feature</span> in FUN HUB® Practice
      allows for voice recording of your students’ pronunciation of letters,
      sounds, sentences, and words, to support oral reading feedback.
    </p>

    <app-admin-student-recording-terms></app-admin-student-recording-terms>

    <p class="mt-3">
      Please note that your educators will have the ability to adjust this
      setting at the individual student level for their classroom. Turning the
      feature off will impact usage of FUN HUB, so please work with your
      educators to ensure they’re using this on/off feature consistent with your
      expectations.
    </p>
  </ng-template>

  <ng-template pTemplate="footer">
    <ng-container *ngIf="responded; else termsOptions">
      <div class="d-flex justify-content-center gap-3 pb-3">
        <p-button
          (onClick)="dismiss()"
          label="No thanks"
          severity="primary"
          [outlined]="true"
        />
        <p-button
          [routerLink]="['/management/settings']"
          label="Take me there"
          severity="primary"
        />
      </div>
    </ng-container>

    <ng-template #termsOptions>
      <div class="d-flex justify-content-center gap-3 pb-3">
        <p-button
          (onClick)="respondToTerms(false)"
          label="Decline"
          severity="primary"
          [outlined]="true"
        />
        <p-button
          (onClick)="respondToTerms(true)"
          label="Turn On Voice Recording"
          severity="primary"
        />
      </div>
    </ng-template>
  </ng-template>
</p-dialog>
