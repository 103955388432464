import { Component, OnInit } from '@angular/core';
import { finalize, iif, map, Observable, switchMap, tap } from 'rxjs';
import { ActiveElement, AnimationEvent, ChartOptions, Plugin } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { SpinnerService } from '@wilson/wilsonng';
import { FunAdminDashboardService } from '../../services/fun-admin-dashboard.service';
import { InitialLoadService } from '../../services/initial-load.service';
import { FunState } from '../../services/fun-state.service';
import { Wilson } from '../../../def/Wilson';
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import GetActivitiesTotalsResponse = Wilson.GetActivitiesTotalsResponse;
import DoughnutDataset = Wilson.DoughnutDataset;
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RecordingSettingsService } from '../../services/recording-settings.service';
import { saveAs } from 'file-saver-es';
import { Workbook } from 'exceljs';
import GetAdminDashboardCsvExport = Wilson.GetAdminDashboardCsvExport;

@Component({
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
  state: FunState;

  chartPlugins: Plugin[] = [ChartDataLabels];
  chartOptions: ChartOptions[] = [];
  chartDefaultOptions: any = {
    animation: {
      animateRotate: false,
      animateScale: true,
      onComplete: (e: AnimationEvent) => {
        if (!e.initial) return;

        const data = e.chart.data as CompletedByResponse;
        const index = this.getDefaultAreaSelection(data);

        e.chart.setActiveElements([{ datasetIndex: 0, index: index }]);
        e.chart.notifyPlugins('afterEvent', { event: { type: 'mousemove' } });
      },
    },
    aspectRatio: 5 / 3,
    events: ['click'],
    hoverBorderWidth: 0,
    hoverOffset: 15,
    layout: { padding: 30 },
    offset: 5,
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: 'black',
        font: { size: 15, family: 'Lexend' },
        formatter: (_, c: Context) => {
          const range = Object.values(
            this.initialLoadService.initialLoad.benchmarkScores
          )[c.dataIndex];
          return `${range.min}% - ${range.max}%`;
        },
        offset: 10,
        opacity: (ctx: Context) => (ctx.active ? 1 : 0),
      },
      legend: { display: false },
      tooltip: { enabled: false },
    },
    responsive: true,
  };
  selectedChartAreas: number[];

  report: CompletedByResponse[] = [];
  statsData: GetActivitiesTotalsResponse[];

  benchmarkScores: BenchmarkScoresViewModel;

  showStudentRecordingDialog: boolean = false;

  constructor(
    private adminDashboardService: FunAdminDashboardService,
    private recordingSettingsService: RecordingSettingsService,
    private initialLoadService: InitialLoadService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;

    this.recordingSettingsService
      .getAdminRecordingPermissionState()
      .pipe(
        tap((resp) => (this.showStudentRecordingDialog = resp.actionRequired))
      )
      .subscribe();
  }

  getReports(): void {
    this.report = undefined;

    if (this.state.isEmpty) {
      return;
    }

    this.spinnerService.show();

    this.getStatsData()
      .pipe(
        switchMap(() =>
          this.state.extras['all']
            ? this.getCompletedByClass()
            : this.getCompletedByStudent()
        ),
        tap((report) => {
          this.selectedChartAreas = Array(report.length)
            .fill(0)
            .map((_, i) => this.getDefaultAreaSelection(report[i]));

          this.chartOptions = Array(report.length)
            .fill(0)
            .map((_, i) => {
              return {
                ...this.chartDefaultOptions,
                onClick: (_, active) => this.selectChart(active, i),
              };
            });

          this.report = report;
        }),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();
  }

  getCompletedByStudent(): Observable<CompletedByResponse[]> {
    return this.adminDashboardService
      .getCompletedByStudent(
        this.state.class.id,
        this.state.class.level,
        this.state.unit,
        this.state.week as number[]
      )
      .pipe(
        map((report) =>
          report.map((item) => ({
            title: item.title,
            labels: item.labels,
            aboveBench: item.studentsAboveBench,
            atRisk: item.studentsAtRisk,
            belowBench: item.studentsBelowBench,
            datasets: item.datasets,
            isStudent: true,
          }))
        )
      );
  }

  getCompletedByClass(): Observable<CompletedByResponse[]> {
    return this.adminDashboardService
      .getCompletedByClass(
        this.state.unit,
        this.state.class.level,
        this.state.class.startingYear,
        this.state.school.id
      )
      .pipe(
        map((report) =>
          report.map((item) => ({
            title: item.title,
            labels: item.labels,
            aboveBench: item.classesAboveBench,
            atRisk: item.classesAtRisk,
            belowBench: item.classesBelowBench,
            datasets: item.datasets,
            isStudent: false,
          }))
        )
      );
  }

  /**
   * Selects the area of the chart that has the highest scores
   * @param report The report data for the doughnut chart
   * @returns The number that corresponds to the section of the chart to be selected
   */
  getDefaultAreaSelection(report: CompletedByResponse): number {
    if (Object.keys(report.aboveBench).length) return 0;
    else if (Object.keys(report.atRisk).length) return 1;
    else if (Object.keys(report.belowBench).length) return 2;
    else return 3;
  }

  getStatsData(): Observable<GetActivitiesTotalsResponse[]> {
    return iif(
      () => this.state.extras['all'],
      this.adminDashboardService.getActivitiesTotalsForSchool(
        this.state.unit,
        this.state.class.level,
        this.state.class.startingYear,
        this.state.school.id
      ),
      this.adminDashboardService.getActivitiesTotalsForClass(
        this.state.unit,
        this.state.class.level,
        this.state.class.id
      )
    ).pipe(tap((response) => (this.statsData = response)));
  }

  selectChart(active: ActiveElement[], id: number) {
    if (active.length) return;
    this.selectedChartAreas[id] = 4;
  }

  selectChartArea(event: any, id: number) {
    this.selectedChartAreas[id] = event.element.index;
  }

  /* istanbul ignore next */
  exportToCsv(): void {
    this.adminDashboardService
      .getAdminDashboardCsvExport(
        this.state.class.level,
        this.state.class.startingYear,
        this.state.school.id
      )
      .pipe(
        tap((data) => {
          this.exportPerformanceDataToCsv(data);
        })
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private exportPerformanceDataToCsv(data: GetAdminDashboardCsvExport[]) {
    const columns = [
      { dataField: 'level', caption: 'Level' },
      { dataField: 'unit', caption: 'Unit' },
      { dataField: 'week', caption: 'Week' },
      { dataField: 'teacher', caption: 'Teacher' },
      { dataField: 'class', caption: 'Class' },
      { dataField: 'average', caption: 'Average Performance' },
      {
        dataField: 'completedActivities',
        caption: 'Number of Completed Activities',
      },
      {
        dataField: 'activeStudents',
        caption: 'Number of Active Students',
      },
    ];

    const workbook = new Workbook();
    const workSheetName = `${this.state.school.name} Level ${this.state.class.level} Performance ${this.state.class.startingYear}`;
    const worksheet = workbook.addWorksheet(workSheetName);

    // Set up the columns in the worksheet
    columns.forEach((column, index) => {
      worksheet.getCell(1, index + 1).value = column.caption;
    });

    // Fill the data in the worksheet
    data.forEach((dataItem, rowIndex) => {
      columns.forEach((column, colIndex) => {
        let cellValue = dataItem[column.dataField];
        if (column.dataField === 'average') cellValue += '%';

        worksheet.getCell(rowIndex + 2, colIndex + 1).value = cellValue;
      });
    });

    // Save the workbook as a Blob
    workbook.csv.writeBuffer().then((buffer: ArrayBuffer) => {
      const data = new Blob([buffer]);
      saveAs(data, `${workSheetName}.csv`);
    });
  }
}

export interface CompletedByResponse {
  title: string;
  labels: string[];
  aboveBench: { [key: string]: string };
  atRisk: { [key: string]: string };
  belowBench: { [key: string]: string };
  datasets: DoughnutDataset[];
  isStudent: boolean;
}
