import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-teacher-student-recording-acknowledgement',
  templateUrl: './teacher-student-recording-acknowledgement.component.html',
})
export class TeacherStudentRecordingAcknowledgementComponent {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  dismiss() {
    this.visible = false;
    this.visibleChange.emit(false);
  }
}
