import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { Wilson } from '../../def/Wilson';
import UpdateUserTenant = Wilson.UpdateUserTenant;

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  constructor(private apiService: ApiService) {}

  updateTenant(tenant: UpdateUserTenant): Observable<void> {
    return this.apiService.post<void>(`tenant/selected-tenant`, tenant);
  }
}
