import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { concatMap, Observable, Subscription, switchMap, tap, timer } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';
import { AuthenticationService } from '@wilson/wilsonng';

@Component({
  selector: 'app-mock-data',
  templateUrl: './mock-data.component.html',
})
export class MockDataComponent implements OnInit, OnDestroy {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();

  loading: boolean = false;
  status: string = 'Loading queue status...';
  statusSubscription: Subscription;

  userInitials: string = '';
  userName: string = '';
  userRoles: FormGroup<{
    districtAdmin: FormControl<boolean>;
    teacher: FormControl<boolean>;
    schoolAdmin: FormControl<boolean>;
  }>;

  canLoad: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private adminService: AdminService,
    private formBuilder: NonNullableFormBuilder,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.userName = this.authenticationService.currentUser.name;
    this.userInitials = this.authenticationService.userInitials;

    this.userRoles = this.formBuilder.group({
      districtAdmin: [
        this.userService.isDistrictAdmin(),
        [Validators.required],
      ],
      schoolAdmin: [this.userService.isSchoolAdmin(), [Validators.required]],
      teacher: [this.userService.isTeacher(), [Validators.required]],
    });

    this.statusSubscription = timer(0, 10000)
      .pipe(concatMap((_) => this.getStatus()))
      .subscribe();
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
  }

  endLoadingStatus(withDialog: boolean = true): Observable<boolean> {
    return this.getStatus().pipe(
      tap(() => {
        this.loading = false;
        this.canLoad = false;

        if (!withDialog) return;

        this.confirmationService.confirm({
          message:
            'Your request is in the queue, continue using Fun360 as usual and your data will appear soon.',
          header: 'Success',
          acceptLabel: 'Ok',
          acceptIcon: 'none',
          rejectVisible: false,
        });
      })
    );
  }

  getStatus(): Observable<boolean> {
    return this.adminService.getStatus().pipe(
      tap((s) => (this.status = s)),
      switchMap(() => this.adminService.canLoadMockData()),
      tap((c) => (this.canLoad = c))
    );
  }

  loadMockData(): void {
    this.loading = true;

    this.adminService
      .loadMockData()
      .pipe(switchMap(() => this.endLoadingStatus()))
      .subscribe();
  }

  resetTenant(event: Event): void {
    this.loading = true;

    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        "Resetting your data will delete any classes, students, unit tests, and assignments you have created in this environment. There's no way to undo this!",
      header: 'Reset Data',
      acceptLabel: 'Reset',
      acceptIcon: 'none',
      acceptButtonStyleClass: 'p-button-danger',
      rejectLabel: 'Cancel',
      rejectIcon: 'none',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.adminService
          .resetTenant()
          .pipe(switchMap(() => this.endLoadingStatus(false)))
          .subscribe();
      },
      reject: () => {
        this.loading = false;
      },
    });
  }

  setUserRoles(): void {
    this.loading = true;

    this.adminService
      .setUserRoles(
        this.userRoles.value.districtAdmin,
        this.userRoles.value.schoolAdmin,
        this.userRoles.value.teacher
      )
      .pipe(tap(() => window.location.reload()))
      .subscribe();
  }
}
