import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  AuthenticationService,
  GoogleAnalyticsService,
  RouterNavigationService,
  User,
} from '@wilson/wilsonng';
import { tap } from 'rxjs';
import { InitialLoadService } from './initial-load.service';
import { UserService } from './user.service';
import mixpanel from 'mixpanel-browser';
import { LogService } from './log.service';
import config from 'devextreme/core/config';
import { Wilson } from 'src/def/Wilson';
import ConnectionType = Wilson.ConnectionType;

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private routerNavigationService: RouterNavigationService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private initialLoadService: InitialLoadService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private logService: LogService
  ) {}

  init(): void {
    this.configureDevextreme();
    this.logService.setupDatadogMonitoring();
    this.authenticationService
      .loginUser()
      .pipe(
        tap((user: User) => {
          this.userService.setUser(user);
          this.initAnalytics();
          this.initRouting();
        })
      )
      .subscribe();
  }

  initAnalytics(): void {
    this.setGoogleAnalyticsKeyAndInitialize();
    this.registerUserWithGoogleAnalytics();
    this.setMixPanelKeyAndInitialize();
    this.registerUserWithMixPanel();
  }

  setGoogleAnalyticsKeyAndInitialize(): void {
    if (!this.initialLoadService.initialLoad.googleAnalyticsKey) {
      return;
    }
    this.googleAnalyticsService.setKey(
      this.initialLoadService.initialLoad.googleAnalyticsKey
    );
    this.googleAnalyticsService.initialize();
  }

  registerUserWithGoogleAnalytics(): void {
    const id = this.authenticationService.currentUser?.wilsonId;
    if (!id) {
      return;
    }
    this.googleAnalyticsService.setUserId(id);
  }

  private configureDevextreme(): void {
    config({
      licenseKey: this.initialLoadService.initialLoad?.devExtremeLicenseKey,
    });
  }

  /* istanbul ignore next */
  private setMixPanelKeyAndInitialize(): void {
    const prodToken = 'ba49ee92e74530223c8cb96c2def29ae';
    const devToken = 'd4ad2e94d03d69386a4072dda5221e1d';
    const key = this.initialLoadService.initialLoad.isProduction
      ? prodToken
      : devToken;
    mixpanel.init(key);
    mixpanel.register({ Application: 'Fun360' });
  }

  /* istanbul ignore next */
  private registerUserWithMixPanel(): void {
    const user = this.authenticationService.currentUser;
    if (user) {
      mixpanel.identify(user.email);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $distinct_id: user.email,
        'Wilson ID': user.wilsonId,
      });

      // do not track test users
      if (user.email === 'uitester@wilsonlanguage.com') {
        mixpanel.opt_out_tracking();
      }
      this.mixpanelTrackLogin();
      mixpanel.track('Arrived On Site');
    }
  }

  private initRouting(): void {
    this.routerNavigationService.startTrackingPreviousUrl();
    this.router.initialNavigation();
  }

  /* istanbul ignore next */
  private mixpanelTrackLogin(): void {
    switch (this.initialLoadService.initialLoad.connection) {
      case ConnectionType.UsernamePassword:
        mixpanel.track('LoginWilson');
        break;
      case ConnectionType.Clever:
        mixpanel.track('LoginClever');
        break;
    }
  }
}
