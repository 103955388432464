import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@wilson/wilsonng';
import { Wilson } from 'src/def/Wilson';
import UserTenant = Wilson.UserTenant;
import { TenantService } from 'src/app/services/tenant.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-tenant',
  templateUrl: './select-tenant.component.html',
})
export class SelectTenantComponent implements OnInit {
  tenants: UserTenant[] = [];
  selectedTenant: UserTenant;

  constructor(
    private authenticationService: AuthenticationService,
    private tenantService: TenantService
  ) {}

  getWindow(): Window { return window; }

  ngOnInit(): void {
    this.initDropdown();
  }

  initDropdown(): void {
    this.tenants = this.authenticationService.currentUser['tenants'];
    this.selectedTenant = this.tenants.find(t => t.isActive);
  }

  onSelectedTenantChange(): void {
    this.updateTenant(this.selectedTenant).subscribe(() => {
      this.getWindow().location.assign('/');
    });
  }

  updateTenant(tenant: UserTenant): Observable<void> {
    return this.tenantService.updateTenant({ id: tenant.id });
  }
}
