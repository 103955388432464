<div class="layout-topbar">
  <button
    #menubutton
    (click)="onMenuToggle()"
    class="p-link layout-menu-button layout-topbar-button m-0"
    [attr.aria-label]="
      layoutState.staticMenuDesktopInactive
        ? 'Expand Sidebar'
        : 'Collapse Sidebar'
    "
    [attr.aria-expanded]="!layoutState.staticMenuDesktopInactive"
    data-automationId="sidebar_toggle"
  >
    <div
      class="desktop-icon wi-icon"
      [class.wi-panel-close]="!layoutState.staticMenuDesktopInactive"
      [class.wi-panel-open]="layoutState.staticMenuDesktopInactive"
    ></div>
    <div class="mobile-icon wi-icon wi-menu"></div>
  </button>

  <div class="d-flex align-items-center justify-content-center ms-auto gap-5">
    <app-select-tenant></app-select-tenant>

    <div
      #topbarmenu
      (click)="profileOverlay.toggle($event)"
      trackClick="FHPProfileDB"
      *ngIf="!!authenticationService.userInitials"
      class="layout-topbar-avatar"
      role="button"
      tabindex="0"
    >
      <p-avatar
        [label]="userInitials"
        [pTooltip]="userName"
        shape="circle"
        size="large"
        tooltipPosition="left"
        data-automationId="user_menu_button"
      ></p-avatar>

      <i
        class="pi ms-2"
        [class.pi-angle-down]="!profileOverlay.overlayVisible"
        [class.pi-angle-up]="profileOverlay.overlayVisible"
      ></i>
    </div>
  </div>
</div>

<p-overlayPanel #profileOverlay styleClass="topbar-profile-overlay">
  <ng-template pTemplate>
    <div class="d-flex flex-column align-content-between">
      <button
        (click)="toggleTheme()"
        class="position-absolute top-0 end-0 p-button-text m-2"
        pTooltip="{{
          layoutService.config.theme === 'light'
            ? 'Switch to Dark Theme'
            : 'Switch to Light Theme'
        }}"
        icon="pi {{
          layoutService.config.theme === 'light' ? 'pi-moon' : 'pi-sun'
        }}"
        pButton
        type="button"
      ></button>

      <div class="d-flex flex-column align-items-center p-3 pb-0">
        <p-avatar
          [label]="userInitials"
          size="large"
          shape="circle"
          class="mt-3"
        ></p-avatar>

        <h4 class="mt-3 mb-3" [innerHTML]="userName"></h4>

        <ng-container *ngIf="!isPersonalTenant">
          <p class="mb-0">{{ districtName }}</p>
          <div *ngFor="let name of schoolNames">
            <p class="mb-0">{{ name }}</p>
          </div>
        </ng-container>

        <p
          *ngFor="let role of userRoles; trackBy: getTracking"
          class="p-text-secondary text-center m-0"
        >
          {{ role }}
        </p>
      </div>

      <div
        class="d-flex flex-column align-content-stretch mt-3"
        *ngIf="{ context: userContext$ | async } as user"
      >
        <a
          href="https://form.asana.com/?k=R4DkVkE7--fjmj78Y-AooQ&d=1157280955375439"
          target="_blank"
          class="p-button p-button-text text-start"
          >Provide Feedback</a
        >

        <button
          (click)="signOut()"
          class="p-button p-button-text text-start"
          label="Log Out"
          type="button"
          pButton
        ></button>

        <button
          (click)="showMockDataModal()"
          *ngIf="mockDataButtonVisibility"
          class="p-button p-button-warning text-start mt-3"
          [style]="{ 'border-radius': '3px' }"
          icon="pi pi-database"
          iconPos="right"
          label="Open Mock Data Tool"
          type="button"
          pButton
          data-automationId="load_mock_data_button"
        ></button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<app-mock-data
  *ngIf="mockDataButtonVisibility && mockDataModalVisible"
  [(visible)]="mockDataModalVisible"
></app-mock-data>
