import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private apiService: ApiService, private httpClient: HttpClient) {}

  canLoadMockData(): Observable<boolean> {
    return this.httpClient.get<boolean>(`admin/CanLoadMockData`);
  }

  getStatus(): Observable<string> {
    return this.httpClient.get<string>(`admin/Status`, { responseType: 'text' as 'json' });
  }

  loadMockData(): Observable<void> {
    return this.apiService.get<void>(`admin/LoadMockData`);
  }

  resetTenant(): Observable<void> {
    return this.apiService.post<void>('admin/ResetTenant/');
  }

  setUserRoles(
    districtAdmin: boolean,
    schoolAdmin: boolean,
    teacher: boolean
  ): Observable<void> {
    return this.apiService.post<void>('admin/SetUserRoles/', {
      districtAdmin: districtAdmin,
      schoolAdmin: schoolAdmin,
      teacher: teacher,
    });
  }
}
