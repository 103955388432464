<p-dialog
  [(visible)]="visible"
  [closable]="!loading"
  [modal]="true"
  [draggable]="false"
  [closeOnEscape]="false"
  [style]="{
    width: '75rem',
    'max-width': '100vw',
    'min-height': '45rem'
  }"
  (visibleChange)="visibleChange.emit($event)"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <h5 class="fw-bold m-0 pe-3">Mock Data Tool</h5>

      <i class="pi pi-exclamation-triangle p-text-secondary"></i>
      <small class="pt-1 p-text-secondary">For Internal Use Only</small>
    </div>
  </ng-template>

  <div class="row" [style]="{ 'font-family': '\'Lexend\', \'sans-serif\'' }">
    <div class="d-flex flex-column col-4">
      <div class="card py-4 px-4 mb-2">
        <div class="d-flex flex-column align-items-center">
          <p-avatar
            [label]="userInitials"
            size="large"
            shape="circle"
          ></p-avatar>

          <h4 class="mt-3 mb-3" [innerHTML]="userName"></h4>

          <div class="d-flex justify-content-between w-100">
            <p class="p-text-secondary m-0">District Admin</p>
            <p-inputSwitch [formControl]="userRoles.controls.districtAdmin" />
          </div>
          <div class="d-flex justify-content-between w-100">
            <p class="p-text-secondary m-0">School Admin</p>
            <p-inputSwitch [formControl]="userRoles.controls.schoolAdmin" />
          </div>
          <div class="d-flex justify-content-between w-100">
            <p class="p-text-secondary m-0">Teacher</p>
            <p-inputSwitch [formControl]="userRoles.controls.teacher" />
          </div>

          <div class="d-flex justify-content-end w-100 mt-3">
            <p-button
              (onClick)="setUserRoles()"
              [disabled]="
                loading ||
                (!userRoles.controls.districtAdmin.value &&
                  !userRoles.controls.schoolAdmin.value &&
                  !userRoles.controls.teacher.value)
              "
              label="Update Roles"
              severity="primary"
              outlined="true"
            />
          </div>
        </div>
      </div>

      <div class="p-3">
        <p class="p-text-secondary text-center">
          Modify your roles above to test
          <span class="fw-bold">Fun360</span> from the perspective of other user
          types, or populate a set of mock data.
        </p>
      </div>
    </div>
    <div class="col-8">
      <div class="card px-4 py-3">
        <h5 class="fw-bold mt-2 mb-3">Mock Data</h5>
        <p class="p-text-secondary">
          Generate a collection of data for Fun360, including unit tests for UTT
          and assignments for FHP, to simulate using the application from the
          perspective of other user types.
          <br><br>
          Once mock data has been loaded, you must reset first to be able to
          generate again.
        </p>

        <p-button
          (onClick)="loadMockData()"
          label="Load Mock Data"
          class="text-end"
          [disabled]="loading || !canLoad"
          [outlined]="true"
          severity="primary"
        />
      </div>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="d-flex justify-content-between">
      <p-button
        (onClick)="resetTenant($event)"
        label="Reset"
        [disabled]="loading"
        [text]="true"
        severity="danger"
      />

      <div class="d-flex align-items-center mx-3 gap-4">
        <i class="pi pi-info-circle"
           [pTooltip]="'Tasks in the mock data tool are handled one at a time. ' +
            'You may need to wait for yours to be processed, but you can ' +
             'still use Fun360 normally in the meantime.'"></i>
        <small class="p-text-secondary fst-italic">
          {{ status }}
        </small>
      </div>
    </div>
  </ng-template>
</p-dialog>
